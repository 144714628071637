/* eslint-disable jsx-a11y/alt-text */
//import Amplitude from 'amplitudejs/dist/amplitude';
//import './js/functions'
import axios from 'axios';
import { useEffect, useState } from 'react';
import './css/app.css';
import Amplitude from './Init';
import PlayerLayout from './PlayerLayout';

//save song meta data in local storage


export default function Container(props) {

  const [song_data, setSong_Data] = useState(false);

  const getPosts = async () => {
    try {
      const songList = await axios.get("/songs.json", { crossdomain: true })

      setSong_Data(songList.data);  // set State
      sessionStorage.setItem('song_data', JSON.stringify(songList.data.songs))

    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getPosts()
  }, [])  // includes empty dependency array


  useEffect(() => {
    if (song_data.length !== 0) {
      Amplitude.init({
        continue_next: true,
        start_song: 0,
        "songs": song_data.songs,
        "default_album_art": "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-to-answer.jpg",
        "preload": "metadta", //only preloads the metadata
        "callbacks": {
          'initialized': console.log("Buffered: ", Amplitude.getBuffered(), Amplitude.getConfig(), Amplitude) ,           
          'readyState': console.log("Ready", Amplitude.getBuffered())

        },
     
        waveforms: {
          sample_rate: 100
        }
      })
    }
  }, [song_data])





  return (

   <PlayerLayout song_data={song_data}/>

  )


}



/* <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/amplitudejs@latest/dist/amplitude.js"></script>
   <meta charset="utf-8" />

   <meta content="width=device-width, initial-scale=1" name="viewport" />


   <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  /> */








