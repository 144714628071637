import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import './css/app.css';
import LeftSidePlayer from './LeftSidePlayer';
import RightSidePlayer from './RightSidePlayer';




const PlayerLayout = (props) => {

   


    return (


        <React.Fragment>

        

            {props.song_data ? <div id="blue-playlist-container">

                <div id="amplitude-player"  >
                    <LeftSidePlayer />
                    <RightSidePlayer songs={props.song_data.songs} />
                </div>
            </div> :
                <div id="amplitude-player"  >
                    <CircularProgress />

                    <p>Loading...</p>
                </div>
            }







        </React.Fragment   >
    )
}

export default PlayerLayout;

/*

    <Paper variant="outlined" style={{"height":"715px" ,"width":"900px", "background-color":"none",  "position": "absolute",
    "top": "50%",
    "left": "50%",
    "transform": "translate(-50%, -50%)" }}>

            {props.song_data ? <div id="blue-playlist-container">
                <div id="amplitude-player" >
                    <LeftSidePlayer />
                    <RightSidePlayer songs={props.song_data.songs} />
                </div>
            </div> :
                <p>Loading...</p>}
        </Paper>

works but to many problems...
         <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
*/