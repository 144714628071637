import { Button, TextField } from "@material-ui/core";
import React from "react";
import { useFormControls } from "./ContactFormControls";
import Typography from '@material-ui/core/Typography';
import { useTheme, withStyles } from "@material-ui/core/styles";

//https://dev.to/hibaeldursi/creating-a-contact-form-with-validation-with-react-and-material-ui-1am0



const inputFieldValues = [
 
  {
    name: "email",
    label: "E-Mail",
    id: "my-email"
  },
  {
    name: "subject",
    label: "Subject",
    id: "my-subject"
  },
  {
    name: "message",
    label: "Message",
    id: "my-message",
    multiline: true,
    rows: 10
  }
];


export const ContactForm = ()=> {
   
  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors
  } = useFormControls();

  //https://material-ui.com/components/textarea-autosize/
  //better than normal textfield

  return (
    
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      {inputFieldValues.map((inputFieldValue, index) => {   
        return (    
          <TextField
          InputLabelProps={{ shrink: true, style:{ color: `#6200EE`, fontSize: 20}}}   
            key={index}
            onChange={handleInputValue}
            onBlur={handleInputValue}
            name={inputFieldValue.name}
            label={inputFieldValue.label+ ":"}
            error={errors[inputFieldValue.name]}
            multiline={inputFieldValue.multiline ?? false}
            fullWidth
            rows={inputFieldValue.rows ?? 1}
            autoComplete="none"
            {...(errors[inputFieldValue.name] && {
              error: true,
              helperText: errors[inputFieldValue.name]
            })}
          />
          
        );
      })}
      <Button
        variant="contained"
        type="submit"
        color="secondary"
        disabled={!formIsValid()}
      >
        Send Message
      </Button>
      

      
    </form>
    
  );
    
}

export default ContactForm;