
import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import React from 'react';



const HamburgerIconButoon = (props) => (

    <>
        <IconButton 
            className={props.className}            
            aria-label="menu"
            onClick={props.handleFunction}
        >
            <Menu fontSize="large" style={{ color: `white` }} />
        </IconButton>
    </>

)

export default HamburgerIconButoon;