import { Box, Button, makeStyles, useTheme } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { useHistory } from 'react-router-dom';
import HamburgerIconButoon from "./HamburgerIconButton";



const useStyles = makeStyles(theme => ({

    desktop: {
        background: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "auto",
        marginTop: "0px",
        marginBottom: "0px",
        maxWidth: "900px"

    },
    menu: {
        display: "flex",

    },
    dialogActions: {
        display: "flex",
        alignContent: "stretch",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: 0,
    },

    mobile: {
        background: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "space-around",
        alignItems: "flex-start",
        marginTop: "0px",
        marginBottom: "0px",
        height: "100%"

    }

}))

const NavBarLinks = (classes) => {
   
    const navLinks = ["CONTACT", "ABOUT", "LINKS", "IMPRESSUM"] //back to button?
    const history= useHistory();

    const clicked=(event)=>{
        console.log(
            "EVENT", event, event.target.textContent)
        if("CONTACT"===event.target.textContent){
          history.push('/contact')
        }
    }


    return (
        <React.Fragment>
            <Box component="span" m={1} className={classes}>

                {navLinks.map((buttons, index) => (
                    <Button key={index + 1}  >
                        <Typography color="primary" variant="subtitle1" gutterBottom onClick={(e) => clicked(e)}>
                            {buttons}
                        </Typography>
                    </Button>))}
            </Box>
        </React.Fragment>
    )
}

export const Navbar = () => {
    const classes = useStyles();
    const theme = useTheme();


    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>

            {matches ?
                <>


                    {NavBarLinks(classes.desktop)}

                </> :
    <>
        <HamburgerIconButoon className={classes.menu} handleFunction={handleClickOpen} />


        <Dialog fullScreen open={open}
            PaperProps={{
                style: {
                    backgroundColor: "rgba(31, 17, 16, 0.75)",
                    boxShadow: 'none',
                },
            }}>

            <DialogActions className={classes.dialogActions}>
                <HamburgerIconButoon className={classes.menu} handleFunction={handleClose} />

            </DialogActions>

            {
            NavBarLinks(classes.mobile)
            }
           
        </Dialog>

    </>
}


        </React.Fragment >

    )
}

