import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import Player from './Player';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import NavBarRouter from './Navbar/NavBarRouter';
import { Navbar } from './Navbar/Navbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';


const theme = createMuiTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1200
    }
  },

  palette:{
    primary:{
      main:"#5D5FEF"
    }
  }
});





           

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>

      <NavBarRouter>
      <Container maxWidth="sm">
        <Player/>
      </Container>
      </NavBarRouter>
      
    </ThemeProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
