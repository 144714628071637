
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme, withStyles } from "@material-ui/core/styles";
import React from "react";
import { useHistory } from 'react-router-dom';
import ContactForm from './ContactForm';





const styles = theme => ({
    dialogActions: {
        display: "flex",
        alignContent: "stretch",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: 23

    },
    textfield: {
        background: "white",
        color: "none",
        padding: '0 0 0 0',
        margin: '10 0 0 0'
    },
    textfield2: {
        height: "300px",
        color: "blue"
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-around"

    },
    dialogContent: {
        margin: "0 0 0 0"
    }


});

const ContactFormular = withStyles(styles)(props => {
    const { classes } = props;
    
    const history = useHistory();



    const [open, setOpen] = React.useState(true);
  

    const handleClose = () => {
        setOpen(false);
        history.push('/')

    };




    return (
        <div>
         <Dialog open={open} onClose={(event, reason) => {
                if (reason !== 'backdropClick') { /*don t w want to close it when clicked send or cancel*/
                    handleClose()
                }
            }} aria-labelledby="form-dialog-title" PaperProps={{
                style: {
                    backgroundColor: "#F1F1F1",
                    boxShadow: 'none',
                },
            }}>


                <DialogTitle className={classes.dialogTitle} style={{ color: `#6200EE` }} id="form-dialog-title">Contact Formular</DialogTitle>
                <DialogContent   >
                    <ContactForm />


                </DialogContent>
                <DialogActions className={classes.dialogActions}  >
                    <Button variant="outlined" onClick={handleClose} style={{ color: `#6200EE` }} >
                        Cancel
                    </Button>
                    <Button className={classes.button} variant="outlined" style={{ color: `#6200EE` }} >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        

        
    )
}
)

export default ContactFormular;

