import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from "react";
import Player from '../Player';
import ContactFormular from './ContactForm/ContactDialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from "@material-ui/core";
import { Navbar } from './Navbar';


const NavBarRouter = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        

        <Router>
            {/*navbar smaall page*/}
           
                        <Switch>

                            <Route path="/" exact 
                            render={() => {
                                return matches ? <><Player/> <Navbar/> </>: <><Navbar/> <Player/> </>;
                              }} />
                            
                            <Route path="/contact" render={() => {
                                return matches ? <> <ContactFormular/> <Navbar/> </>: <><Navbar/> <ContactFormular/> </>;
                              }} /> 
                     </Switch>
                
                
                
                
                {/*navbar big page*/}
            </Router>
           
            )
}



            export default NavBarRouter;